export const environment = {

  // this is overwritten in the CI
  apiUrl: 'https://api.coronavis.dbvis.de/',

  // this is overwritten in the CI
  tileServerUrl: 'https://tiles.coronavis.dbvis.de/',

  version: 'v1.22.81',

  env: 'production',

  dsn: 'https://6ff2c8f17a534f83bcf18385529d1f59@sentry.io/5185839'
};
